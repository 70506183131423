import create from "zustand";

import StorageHandler from "../../../../../../utils/StorageHandler";

const storage = new StorageHandler();

/**
 * If the user didn't sent something or the last sending ist older or equals 5 minutes ago,
 * he can send again.
 */
const canSend = (timestamp) => {
    if (timestamp === null) {
        return true;
    }
    return Math.floor((Date.now() - parseInt(timestamp)) / 1000 / 60) >= 5;
};

const useStore = create((set, get) => ({
    formData: {
        name: "",
        email: "",
        zipcode: "",
        message: "",
    },
    sending: false,
    emailSent: !canSend(
        storage.get("pellets-waermekonto:contact-form:sent-at", null)
    ),
    sendingError: false,
    errors: [],
    onChange: (event) => {
        set((state) => ({
            formData: {
                ...state.formData,
                ...{ [event.target.name]: event.target.value },
            },
        }));
    },
    setErrors: (errors) => set({ errors }),
    setSending: (sending) => set({ sending }),
    setSendingError: (sendingError) => set({ sendingError }),
    setEmailSent: (emailSent) => set({ emailSent }),
    addFormError: (error) =>
        set((state) => {
            if (state.errors.includes(error)) {
                return state;
            }
            return { errors: [...state.errors, error] };
        }),
    clearError: (error) =>
        set((state) => ({
            errors: state.errors.filter((item) => item !== error),
        })),
    hasErrorAt: (error) => get().errors.includes(error),
}));

export default useStore;
